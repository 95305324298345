var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vid-popup"
  }, [_c('b-modal', {
    attrs: {
      "id": "viewvid",
      "centered": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "vid-popup-header text-center"
        }, [_c('h4', [_vm._v(_vm._s(_vm.row.title))]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": function click($event) {
              _vm.show = false;
            }
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "vid-popup-form"
  }, [_vm.row.video_url ? _c('iframe', {
    attrs: {
      "id": "player",
      "type": "text/html",
      "width": "100%",
      "height": "450",
      "allowfullscreen": "",
      "src": _vm.row.video_url,
      "frameborder": "0"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "vidDescption mt-3"
  }, [_c('figure', [_c('strong', [_vm._v("Uploaded By:")]), _vm._v(_vm._s(_vm.row.uploaded_by_name) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.row.description) + " ")])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }