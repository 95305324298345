<template>
  <div class="row" v-if="rows.records && rows.records.length">
    <div
      class="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-4"
      v-for="(row, index) in rows.records"
      :key="index"
    >
      <div class="video-card">
        <a href="javascript:void(0)" @click="openVideo(row.id)">
          <div
            class="vid-thumb vid-thumb-box"
            v-b-tooltip.hover
            title="Play Video"
            v-if="row.thumbnail_url"
            :style="`background-image: url(${row.thumbnail_url})`" 

          >
            
            <div class="play-button">
              <i class="fas fa-play"></i>
            </div>
          </div>
          <div
            class="vid-thumb vid-thumb-box"
            v-b-tooltip.hover
            title="Play Video"
            :style="`background-image: url(${Videothumb})`" 
            v-else
          >
            <div class="play-button">
              <i class="fas fa-play"></i>
            </div>
          </div>
        </a>
        <div class="video-shortDetail split">
          <a href="javascript:void(0)" v-b-modal.viewvid>
            <div class="video-text">
              <h2>{{ getDisplayTitle(row.title) }}</h2>
              <p>
                Category : {{ getCategoryName(row) }} {{ getSubCategoryName(row) }}
              </p>
              <figure>
                <strong>Uploaded By:</strong> {{ row.uploaded_by_name }}
              </figure>
            </div>
          </a>
          <div class="video-action">
            <custom-button
              title="Edit"
              className="edit"
              iconClass="fas fa-pen "
              @onClicked="editRow(row.id)"
            />
            <custom-button
              title="Delete"
              className="delete"
              iconClass="fas fa-trash"
              @onClicked="deleteRow(row.id)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p class="text-center my-3 py-3">No Records Found</p>
  </div>
</template>
<script>
import Videothumb from "@/assets/images/video-placeholder.png"
import Api from "@/resource/Api";
export default {
  name: "VideoList",
  data(){
    return{
      Videothumb
    }
  },
  props: {
    rows: {
      required: true,
      default: {},
    },
  },
  methods: {
    getDisplayTitle(title) {
      return this.truncateText(title, 20);
    },
    truncateText(text, length) {
      return text.length > length ? text.substr(0, length - 1) + "..." : text;
    },
    editRow(id) {
      this.$emit("openEditForm", id);
    },
    async deleteRow(id) {
      let url = `v2/video/${id}/delete`;
      this.$confirm({
        title: "Delete Video",
        message: `Do you want to delete video ?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.delete(url)
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                this.$emit("onSuccess");
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "error");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
    openVideo(id) {
      this.$emit("onOpenVideo", id);
    },
    getCategoryName(row) {
      return row.category != null
        ? row.category.parent != null
          ? row.category.parent.name
          : row.category.name
        : "";
    },
    getSubCategoryName(row) {
      return row.category != null
        ? row.category.parent != null
          ? `/ ${row.category.name}`
          : ""
        : "";
    },
  },
};
</script>


