var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.rows.records && _vm.rows.records.length ? _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.rows.records, function (row, index) {
    return _c('div', {
      key: index,
      staticClass: "col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mt-4"
    }, [_c('div', {
      staticClass: "video-card"
    }, [_c('a', {
      attrs: {
        "href": "javascript:void(0)"
      },
      on: {
        "click": function click($event) {
          return _vm.openVideo(row.id);
        }
      }
    }, [row.thumbnail_url ? _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "vid-thumb vid-thumb-box",
      style: "background-image: url(".concat(row.thumbnail_url, ")"),
      attrs: {
        "title": "Play Video"
      }
    }, [_vm._m(0, true)]) : _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "vid-thumb vid-thumb-box",
      style: "background-image: url(".concat(_vm.Videothumb, ")"),
      attrs: {
        "title": "Play Video"
      }
    }, [_vm._m(1, true)])]), _c('div', {
      staticClass: "video-shortDetail split"
    }, [_c('a', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.viewvid",
        modifiers: {
          "viewvid": true
        }
      }],
      attrs: {
        "href": "javascript:void(0)"
      }
    }, [_c('div', {
      staticClass: "video-text"
    }, [_c('h2', [_vm._v(_vm._s(_vm.getDisplayTitle(row.title)))]), _c('p', [_vm._v(" Category : " + _vm._s(_vm.getCategoryName(row)) + " " + _vm._s(_vm.getSubCategoryName(row)) + " ")]), _c('figure', [_c('strong', [_vm._v("Uploaded By:")]), _vm._v(" " + _vm._s(row.uploaded_by_name) + " ")])])]), _c('div', {
      staticClass: "video-action"
    }, [_c('custom-button', {
      attrs: {
        "title": "Edit",
        "className": "edit",
        "iconClass": "fas fa-pen "
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.editRow(row.id);
        }
      }
    }), _c('custom-button', {
      attrs: {
        "title": "Delete",
        "className": "delete",
        "iconClass": "fas fa-trash"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.deleteRow(row.id);
        }
      }
    })], 1)])])]);
  }), 0) : _c('div', [_c('p', {
    staticClass: "text-center my-3 py-3"
  }, [_vm._v("No Records Found")])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "play-button"
  }, [_c('i', {
    staticClass: "fas fa-play"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "play-button"
  }, [_c('i', {
    staticClass: "fas fa-play"
  })]);
}]

export { render, staticRenderFns }