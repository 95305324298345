<template>
  <b-form-group :class="formGroupClass">
    <validation-provider
      :name="labelName"
      :rules="rules"
      v-slot="validationContext"
    >
      <label v-if="label" :for="`label-'${label}`">
        {{ label }}
        <span class="required-indc" v-if="rules && rules.required"> * </span>
      </label>
      <b-form-file
        :id="`label-'${label}`"
        browse-text="Choose File"
        :state="getValidationState(validationContext)"
        @change="fileHandler"
        v-model="computedVmodel"
      >
      </b-form-file>
      <div class="uploaded-video">
        <div class="video-preview" v-if="videoSrc">
          <iframe
            id="player"
            type="text/html"
            width="100%"
            height="200"
            allowfullscreen
            :src="videoSrc"
            frameborder="0"
          ></iframe>
        </div>
        <div class="py-1 file-msg" v-if="fileMsg">
          <p>Note: {{ fileMsg }}</p>
        </div>
      </div>
      <error-text
        v-if="
          validationContext.errors[0] || (apiErrors && apiErrors[fieldName])
        "
        :errors="
          validationContext.errors && validationContext.errors.length > 0
            ? validationContext.errors
            : apiErrors
            ? apiErrors[fieldName]
            : ''
        "
        :field="fieldName"
      />
    </validation-provider>
  </b-form-group>
</template>
<script>
export default {
  name: "CustomFileUpload",
  props: {
    apiErrors: {
      required: false,
    },
    vmodel: {
      required: true,
    },
    label: {
      required: true,
    },
    formGroupClass: {
      required: false,
      default: "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
    },
    type: {
      required: false,
      default: "file",
    },
    rules: {
      type: Object,
      required: false,
      default: null,
    },
    labelName: {
      required: false,
    },
    fieldName: {
      required: true,
    },
    fileUrl: {
      required: false,
      default: "",
    },
  },
  data: () => ({
    file: null,
    videoSrc: null,
    fileMsg: "",
  }),
  computed: {
    computedVmodel: {
      get() {
        return this.vmodel;
      },
      set() {
        this.$emit("update:vmodel", this.file ? this.file : null);
      },
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      if (this.rules) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
    inputHandler() {
      this.$emit("inputHandler");
    },
    fileHandler(e) {
      const file = e.target.files[0];
      this.previewFile(file);
    },
    previewFile(file = null) {
      this.fileMsg = "";
      if (!file) {
        this.file = "";
        this.videoSrc = "";
        return false;
      }
      this.file = file;
      let { size, type } = file;
      if (size / (1024 * 1024) > 100 || type != "video/mp4") {
        this.videoSrc = "";
        this.$notify("Unable To Preview File", "error");
        this.fileMsg = "File must be of mp4 format below 100MB for preview";
        setTimeout(() => {
          this.fileMsg = "";
        }, 5000);
        return false;
      }
      this.videoSrc = URL.createObjectURL(file);
    },
  },
  watch: {
    fileUrl: {
      immediate: true,
      handler(url) {
        if (url) {
          this.videoSrc = url;
        }
      },
    },
  },
};
</script>

<style scoped>
.video-preview {
  padding-top: 1.2rem;
}

.file-msg {
  font-size: 14px;
}
</style>
