<template>
  <div class="page-wrapper manageVid-wrapper">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <div class="titleBtn">
          <h1>Manage Videos</h1>
          <button class="videoAdd-btn" @click="openForm"><i aria-hidden="true" class="fa fa-plus"></i> Add Video</button>
        </div>
      </div>
        <video-list
          :rows="rows"
          v-if="rows"
          @onSuccess="onSuccess"
          @openEditForm="openEditForm"
          @onOpenVideo="openVideoModal"
        />
        <pagination :rows.sync="rows" class="mt-3"/>
      
    </div>
    <video-form
      :form.sync="form"
      :rowId.sync="rowId"
      :fileUrl="fileUrl"
      @onSuccess="onSuccess"
    />
    <view-video :row="rowData" />
  </div>
</template>
<script>
import VideoList from "./partials/VideoList.vue";
import VideoForm from "./partials/VideoForm.vue";
import ViewVideo from "./partials/ViewVideo.vue";
import Api from "@/resource/Api";

const formFields = {
  title: "",
  category_id: "",
  description: "",
  video: null,
  thumbnail: null,
  thumbnail_url: null,
  sub_category_id: "",
};

export default {
  name: "ManageVideo",
  components: {
    VideoList,
    VideoForm,
    ViewVideo
  },
  data: () => ({
    rows: {},
    rowId: "",
    rowData: {},
    form: { ...formFields },
    fileUrl: "",
    breadcrumbItems: [{ title: "Manage Videos", active: true }],
  }),
  methods: {
    async getVideoList(loadIcon = true) {
      const url = "v2/video/list";
      let loader;
      if (loadIcon) {
        loader = this.$loading.show();
      }
      await Api.get(url)
        .then((res) => {
          this.rows = res.data;
        })
        .catch(() => {
          this.rows = [];
        })
        .finally(() => {
          if (loader) {
            loader.hide();
          }
        });
    },
    async getVideoDetail(id, loadIcon = true) {
      let url = `v2/video/${id}/show`;
      let loader;
      if (loadIcon) {
        loader = this.$loading.show();
        setTimeout(() => {
          loader.hide();
        }, 1000);
      }
      await Api.get(url)
        .then((res) => {
          let rowData = res.data.data;
          this.rowData = rowData;
          // For Editing Form
          if (this.rowId) {
            this.fileUrl = rowData.video_url;
            this.form = {
              title: rowData.title,
              description: rowData.description,
              video: null,
              thumbnail: null,
              thumbnail_url: rowData.thumbnail_url,
              category_id:
                rowData.category != null
                  ? rowData.category.parent != null
                    ? rowData.category.parent.id
                    : rowData.category.id
                  : "",
              sub_category_id:
                rowData.category != null
                  ? rowData.category.parent != null
                    ? rowData.category.id
                    : ""
                  : "",
            };
          }
        })
        .catch(() => {
          this.rowData = {};
        })
        .finally(() => {
          if (loader) {
            loader.hide();
          }
        });
    },
    openForm() {
      this.rowId = null;
      this.form = {...formFields};
      this.$bvModal.show("addvid");
    },
    openEditForm(id) {
      this.rowId = id;
      this.form = {...formFields};
      this.getVideoDetail(id,0);
      this.$bvModal.show("addvid");
    },
    openVideoModal(id) {
      this.rowData = {};
      this.getVideoDetail(id, 1);
      //avoid call stack size error due to loading overlay
      setTimeout(() => {
        this.$bvModal.show("viewvid");
      }, 1000);
    },
    onSuccess() {
      this.getVideoList(0);
    },
  },
  created() {
    this.getVideoList();
  },
};
</script>
<style src="../../assets/css/managevideo.css"></style>