var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper manageVid-wrapper"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('div', {
    staticClass: "page-title"
  }, [_c('div', {
    staticClass: "titleBtn"
  }, [_c('h1', [_vm._v("Manage Videos")]), _c('button', {
    staticClass: "videoAdd-btn",
    on: {
      "click": _vm.openForm
    }
  }, [_c('i', {
    staticClass: "fa fa-plus",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" Add Video")])])]), _vm.rows ? _c('video-list', {
    attrs: {
      "rows": _vm.rows
    },
    on: {
      "onSuccess": _vm.onSuccess,
      "openEditForm": _vm.openEditForm,
      "onOpenVideo": _vm.openVideoModal
    }
  }) : _vm._e(), _c('pagination', {
    staticClass: "mt-3",
    attrs: {
      "rows": _vm.rows
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  })], 1), _c('video-form', {
    attrs: {
      "form": _vm.form,
      "rowId": _vm.rowId,
      "fileUrl": _vm.fileUrl
    },
    on: {
      "update:form": function updateForm($event) {
        _vm.form = $event;
      },
      "update:rowId": function updateRowId($event) {
        _vm.rowId = $event;
      },
      "update:row-id": function updateRowId($event) {
        _vm.rowId = $event;
      },
      "onSuccess": _vm.onSuccess
    }
  }), _c('view-video', {
    attrs: {
      "row": _vm.rowData
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }