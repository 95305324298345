<template>
  <div class="vid-popup">
    <b-modal id="viewvid" centered size="lg" v-model="show">
      <template #modal-header>
        <div class="vid-popup-header text-center">
          <h4>{{ row.title }}</h4>

          <b-button variant="close-btn" @click="show = false">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="vid-popup-form">
          <!-- <video width="100%" controls autoplay name="media">
            <source :src="row.video_url" type="video/mp4"/>
          </video> -->
          <iframe v-if="row.video_url"
            id="player"
            type="text/html"
            width="100%"
            height="450"
            allowfullscreen
            :src="row.video_url"
            frameborder="0"
          ></iframe>
          <div class="vidDescption mt-3">
            <figure>
              <strong>Uploaded By:</strong>{{ row.uploaded_by_name }}
            </figure>
            <p>
              {{ row.description }}
            </p>
          </div>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "ViewVideo",
  props: {
    row: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>
<style>
.modal-header {
  justify-content: center;
  border: 0;
  padding: 25px 0;
  position: relative;
}
.modal-footer {
  display: none;
}
.vid-popup-header button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.modal-content {
  border: 0;
  border-radius: 15px;
}
.vid-popup-header h4 {
  font-size: 20px;
  color: #2c3f58;
  font-weight: 600;
  margin: 0;
}
.addBtn {
  background: #03c3ec;
  border-radius: 6px;
  color: #fff;
  outline: none;
  border: 0;
  padding: 10px 0;
  margin-top: 10px;
  transition: all 0.6s ease;
  padding: 10px 50px;
}
.addBtn:hover {
  background: #455c75;
  transition: all ease 0.4s;
}
</style>