<template>
  <div class="form-group mb-3">
    <validation-provider
      :name="labelName"
      :rules="rules"
      v-slot="validationContext"
    >
      <label>
        <span v-html="computedLabel" />
      </label>

      <multiselect
        v-model="computedInputData"
        :placeholder="computedPlaceholder"
        :tag-placeholder="computedTagPlaceholder"
        :options="options"
        :multiple="false"
        :searchable="true"
        :taggable="isTaggable"
        :internal-search="internalSearch"
        :clear-on-select="false"
        :close-on-select="closeOnSelect"
        :preserve-search="true"
        :options-limit="optionsLimit"
        :show-no-results="false"
        :hide-selected="false"
        :custom-label="customLabel"
        :class="selectClassName(validationContext)"
        :loading="isLoading"
        @search-change="searchHandler"
        @input="inputHandler"
        @tag="addTag"
      >
        <template slot="selection" slot-scope="{ values }">
          <span class="multiselect__single" v-if="values.length">
            {{ values.includes("All") ? "All " : values.length }}
            Options Selected
          </span>
        </template>
      </multiselect>
      <b-form-invalid-feedback id="input-feedback ">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </validation-provider>
  </div>
</template>
<script>
export default {
  name: "MultiSelectable",
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Object,
      required: false,
      default: null,
    },
    labelName: {
      type: String,
      required: true,
    },
    inputType: {
      type: String,
      required: false,
      default: "text",
    },
    vmodel: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    formGroupClass: {
      type: String,
      required: false,
    },
    activeData: {
      required: true,
    },
    options: {
      required: true,
    },
    optionsLimit: {
      required: true,
    },
    labelKey: {
      required: false,
    },
    localSearch: {
      required: false,
    },
    isLoading: {
      required: false,
    },
    // trackBy: {
    //   required: true,
    // },
    // displayLabel: {
    //   required: true,
    // },
    isTaggable: {
      required: false,
      default: false,
    },
    internalSearch: {
      required: false,
      default: true,
    },
    closeOnSelect: {
      required: false,
      default: false,
    },
    tagPlaceholder:{
        required :false,
        default:''
    },
    customLabel :{
      required :false
    }
  },
  data: () => ({
    isAll: false,
    allCount: 0,
    inputSelectData: [],
  }),
  computed: {
    computedPlaceholder() {
      if (this.placeholder == "") {
        return `Select ${this.labelName}`;
      }
      return this.placeholder;
    },
    computedTagPlaceholder(){
        if(this.tagPlaceholder == ""){
            return 'Press Enter To Add Tag'
        }
        return this.tagPlaceholder;
    },
    computedLabel() {
      if (this.rules && this.rules.required) {
        return this.labelName + "<span class='input-required'> * </span>";
      }
      return this.labelName;
    },
    computedInputData: {
      get: function () {
        return this.inputSelectData;
      },
      set: function (value) {
        this.inputSelectData = value;
        this.$emit("update:vmodel", value ? value : []);
      },
    },
  },
  methods: {
    searchHandler(value) {
      this.$emit("search", value);
    },
    openHandler() {
      this.$emit("open");
    },
    selectClassName(validationContext) {
      let className = "";
      if (validationContext.errors[0]) {
        className = "invalid-input-block";
      } else if (validationContext.valid && this.rules && this.rules.required) {
        className = "valid-input-block";
      } else {
        className = "";
      }
      return `${className}`;
    },
    autoCompleteHandler() {
      document
        .querySelector(".multiselect__input")
        .setAttribute("autocomplete", "off");
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    inputHandler() {
      this.$emit("onInput", this.inputSelectData);
    },
    addTag(query) {
      this.$emit("tag", query);
    },
  },
  watch: {
    vmodel: function (newValue) {
      if (!newValue || newValue.length < 1) {
        this.inputSelectData = [];
      } else {
        this.inputSelectData = newValue;
      }
    },
  },
};
</script>
<style scoped>
.input-required {
  color: red !important;
}

.invalid-input-block > .multiselect__tags {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.valid-input-block > .multiselect__tags {
  /* border-color: #198754; */
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.invalid-feedback {
  display: block;
}
</style>
