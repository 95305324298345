<template>
  <div class="vid-popup">
    <b-modal id="addvid" centered size="md" v-model="show" no-close-on-backdrop>
      <template #modal-header>
        <div class="vid-popup-header text-center">
          <h4>{{ rowId ? "Edit" : "Add" }} Video</h4>
          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="vid-popup-form">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)" ref="formContainer">
              <input-text
                labelName="Title"
                labelFor="title"
                :vmodel.sync="videoForm.title"
                formGroupClass="form-group mb-3"
                inputClass="custom-input"
                :rules="{ required: true }"
              />
              <input-single-select
                labelName="Category"
                :vmodel.sync="videoForm.category_id"
                formGroupClass="form-group mb-3"
                :optionsLimit="optionsLimit"
                :isTaggable="isTaggable"
                :activeData.sync="subCategories"
                tagPlaceholder="Add Category"
                :closeOnSelect="true"
                @onInput="onCategorySelect"
                @tag="addCategory"
                :isLoading="isCategoryLoading"
                :options="categories.map((data) => data.id)"
                :custom-label="
                  (categoryId) =>
                    categories.length &&
                    categories.find((data) => data.id == categoryId).name
                "
                :rules="{ required: true }"
              />
              <input-single-select
                v-if="showSubCategories"
                labelName="Sub Category"
                :vmodel.sync="videoForm.sub_category_id"
                tagPlaceholder="Add Sub Category"
                formGroupClass="form-group mb-3"
                :optionsLimit="optionsLimit"
                :isTaggable="isTaggable"
                :activeData.sync="subCategories"
                :closeOnSelect="true"
                @tag="addSubCategory"
                :isLoading="isSubCategoryLoading"
                :options="subCategories.map((data) => data.id)"
                :custom-label="
                  (subCategoryId) =>
                    subCategories.length &&
                    subCategories.find((data) => data.id == subCategoryId).name
                "
              />
              <input-textarea
                labelName="Description"
                labelFor="description"
                :vmodel.sync="videoForm.description"
                formGroupClass="form-group mb-3"
                inputClass="custom-input"
                :rules="{ required: true }"
              />
              <custom-file-upload
                label="Upload Video"
                labelName="video"
                :vmodel.sync="videoForm.video"
                formGroupClass="form-group mb-3"
                inputName="video"
                :rules="{ required: rowId ? false : true }"
                fieldName="video"
                :apiErrors="apiErrors"
                :fileUrl="videoUrl"
                placeholder="hello"
              />
              <input-image
                labelName="Upload Thumbnail "
                labelFor="thumbnail"
                :vmodel.sync="form.thumbnail"
                formGroupClass="form-group mb-3"
                inputName="thumbnail"
                :rules="{ required: false }"
                :imgSrc="form.thumbnail_url"
                :apiErrors="apiErrors"
                fieldName="thumbnail"
                :deleteIcon="false"
              />
              <div class="form-group mb-3 text-center">
                <button class="addBtn" type="submit">
                  {{
                    rowId
                      ? isSubmit
                        ? "Updating"
                        : "Update"
                      : isSubmit
                      ? "Adding"
                      : "Add"
                  }}
                  Video
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import CustomFileUpload from "./CustomFileUpload.vue";
import InputSingleSelect from "./InputSingleSelect.vue";

export default {
  name: "VideoForm",
  components: {
    CustomFileUpload,
    InputSingleSelect,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    rowId: {
      required: false,
      default: null,
    },
    fileUrl: {
      required: false,
      default: "",
    },
  },
  data() {
    return {
      show: false,
      isSubmit: false,
      apiErrors: null,
      isTaggable: true,
      optionsLimit: 200,
      categories: [],
      subCategories: [],
      isCategoryLoading: false,
      isSubCategoryLoading: false,
      parentCategoryId: "",
      showSubCategories: false,
      videoForm: {},
      videoUrl: "",
    };
  },
  methods: {
    submit() {
      this.isSubmit = true;
      let form = this.videoForm;
      let formData = new FormData();
      if (!form.thumbnail) {
        delete form["thumbnail"];
      }
      if (this.rowId) {
        formData.append("_method", "PUT");
        formData.append("id", this.rowId);
        //delete entries 'video' and 'thumbnail' on updating on having null value
        if (!form.video) {
          delete form["video"];
        }
        if (!form.thumbnail) {
          delete form["thumbnail"];
        }
      }
      for (const [key, value] of Object.entries(form)) {
        formData.append(key, value);
      }
      this.save(formData);
    },
    async save(formData) {
      let url = this.rowId ? "v2/video/update" : "v2/video/store";
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
      });
      Api.post(url, formData)
        .then((res) => {
          this.$notify(res.data.message);
          this.$emit("onSuccess");
          this.closeModal();
        })
        .catch((err) => {
          this.errorResponse(err);
        })
        .finally(() => {
          this.isSubmit = false;
          loader.hide();
        });
    },
    errorResponse(error) {
      if (error.response.status == 422) {
        this.apiErrors = { ...error.response.data.errors };
        setTimeout(() => {
          this.apiErrors = null;
        }, 5000);
      } else {
        this.$notify(error.response.data.message, "error");
      }
    },
    closeModal() {
      this.videoForm = {};
      this.videoUrl = "";
      this.$emit("update:rowId", null);
      this.$bvModal.hide("addvid");
    },

    // Dropdown Related Functions
    getParentCategoryList(selectedId = null) {
      let url = "v2/video/category/list";
      this.isCategoryLoading = true;
      Api.get(url)
        .then((res) => {
          this.categories = res.data.data;
          if (selectedId) {
            this.videoForm.category_id = selectedId;
            this.videoForm.sub_category_id = "";
            // Repoulate Sub Category Dropdown On Adding Category Through Query
            this.getSubCategoryList(selectedId);
            this.showSubCategories = true;
          }
        })
        .catch(() => {
          this.categories = [];
        })
        .finally(() => {
          this.isCategoryLoading = false;
        });
    },
    getSubCategoryList(parentId, selectedId = null) {
      if (!parentId) {
        return false;
      }
      this.isSubCategoryLoading = true;
      let url = `v2/video/category/${parentId}/sub-categories`;
      Api.get(url)
        .then((res) => {
          this.subCategories = res.data.data;
          if (selectedId) {
            this.videoForm.sub_category_id = selectedId;
          }
        })
        .catch(() => {
          this.subCategories = [];
        })
        .finally(() => {
          this.isSubCategoryLoading = false;
        });
    },
    addCategory(query) {
      if (!query.length) {
        return false;
      }
      this.isCategoryLoading = true;
      let url = "v2/video/category/store";
      Api.post(url, {
        name: query,
        parent_id: null,
      })
        .then((res) => {
          this.$notify(res.data.message);
          this.getParentCategoryList(res.data.data.id);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isCategoryLoading = false;
        });
    },
    addSubCategory(query) {
      if (!query) {
        return false;
      }
      if (this.videoForm) {
        this.parentCategoryId = this.videoForm.category_id;
      }
      if (!this.parentCategoryId) {
        this.$notify("Please Select Category", "error");
        return false;
      }
      this.isSubCategoryLoading = true;
      let url = "v2/video/category/store";
      Api.post(url, {
        name: query,
        parent_id: this.parentCategoryId,
      })
        .then((res) => {
          this.$notify(res.data.message);
          this.getSubCategoryList(this.parentCategoryId, res.data.data.id);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isSubCategoryLoading = false;
        });
    },
    onCategorySelect(categoryId) {
      if (!categoryId) {
        this.parentCategoryId = "";
        return false;
      }
      this.showSubCategories = true;
      this.parentCategoryId = categoryId;
      this.getSubCategoryList(categoryId);
    },
  },
  mounted() {
    this.getParentCategoryList();
  },
  watch: {
    form: function (data) {
      this.videoForm = data;
      this.subCategories = [];
      this.showSubCategories = false;
      if (this.rowId && data.category_id != null) {
        this.getSubCategoryList(data.category_id);
        this.showSubCategories = true;
      }
    },
    fileUrl: {
      immediate: true,
      handler(url) {
        if (url) {
          this.videoUrl = url;
        }
      },
    },
  },
};
</script>
<style>
.modal-header {
  justify-content: center;
  border: 0;
  padding: 25px 0;
  position: relative;
}
.modal-footer {
  display: none;
}
.vid-popup-header button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.modal-content {
  border: 0;
  border-radius: 15px;
}
.vid-popup-header h4 {
  font-size: 20px;
  color: #2c3f58;
  font-weight: 600;
  margin: 0;
}
.addBtn {
  background: #03c3ec;
  border-radius: 6px;
  color: #fff;
  outline: none;
  border: 0;
  padding: 10px 0;
  margin-top: 10px;
  transition: all 0.6s ease;
  padding: 10px 50px;
}
.addBtn:hover {
  background: #455c75;
  transition: all ease 0.4s;
}
div.multiselect{
  margin-left: 0 !important;
  width:100% !important;
}
</style>