var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    class: _vm.formGroupClass
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.labelName,
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_vm.label ? _c('label', {
          attrs: {
            "for": "label-'".concat(_vm.label)
          }
        }, [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.rules && _vm.rules.required ? _c('span', {
          staticClass: "required-indc"
        }, [_vm._v(" * ")]) : _vm._e()]) : _vm._e(), _c('b-form-file', {
          attrs: {
            "id": "label-'".concat(_vm.label),
            "browse-text": "Choose File",
            "state": _vm.getValidationState(validationContext)
          },
          on: {
            "change": _vm.fileHandler
          },
          model: {
            value: _vm.computedVmodel,
            callback: function callback($$v) {
              _vm.computedVmodel = $$v;
            },
            expression: "computedVmodel"
          }
        }), _c('div', {
          staticClass: "uploaded-video"
        }, [_vm.videoSrc ? _c('div', {
          staticClass: "video-preview"
        }, [_c('iframe', {
          attrs: {
            "id": "player",
            "type": "text/html",
            "width": "100%",
            "height": "200",
            "allowfullscreen": "",
            "src": _vm.videoSrc,
            "frameborder": "0"
          }
        })]) : _vm._e(), _vm.fileMsg ? _c('div', {
          staticClass: "py-1 file-msg"
        }, [_c('p', [_vm._v("Note: " + _vm._s(_vm.fileMsg))])]) : _vm._e()]), validationContext.errors[0] || _vm.apiErrors && _vm.apiErrors[_vm.fieldName] ? _c('error-text', {
          attrs: {
            "errors": validationContext.errors && validationContext.errors.length > 0 ? validationContext.errors : _vm.apiErrors ? _vm.apiErrors[_vm.fieldName] : '',
            "field": _vm.fieldName
          }
        }) : _vm._e()];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }