var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vid-popup"
  }, [_c('b-modal', {
    attrs: {
      "id": "addvid",
      "centered": "",
      "size": "md",
      "no-close-on-backdrop": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "vid-popup-header text-center"
        }, [_c('h4', [_vm._v(_vm._s(_vm.rowId ? "Edit" : "Add") + " Video")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "vid-popup-form"
  }, [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          ref: "formContainer",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.submit);
            }
          }
        }, [_c('input-text', {
          attrs: {
            "labelName": "Title",
            "labelFor": "title",
            "vmodel": _vm.videoForm.title,
            "formGroupClass": "form-group mb-3",
            "inputClass": "custom-input",
            "rules": {
              required: true
            }
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.videoForm, "title", $event);
            }
          }
        }), _c('input-single-select', {
          attrs: {
            "labelName": "Category",
            "vmodel": _vm.videoForm.category_id,
            "formGroupClass": "form-group mb-3",
            "optionsLimit": _vm.optionsLimit,
            "isTaggable": _vm.isTaggable,
            "activeData": _vm.subCategories,
            "tagPlaceholder": "Add Category",
            "closeOnSelect": true,
            "isLoading": _vm.isCategoryLoading,
            "options": _vm.categories.map(function (data) {
              return data.id;
            }),
            "custom-label": function customLabel(categoryId) {
              return _vm.categories.length && _vm.categories.find(function (data) {
                return data.id == categoryId;
              }).name;
            },
            "rules": {
              required: true
            }
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.videoForm, "category_id", $event);
            },
            "update:activeData": function updateActiveData($event) {
              _vm.subCategories = $event;
            },
            "update:active-data": function updateActiveData($event) {
              _vm.subCategories = $event;
            },
            "onInput": _vm.onCategorySelect,
            "tag": _vm.addCategory
          }
        }), _vm.showSubCategories ? _c('input-single-select', {
          attrs: {
            "labelName": "Sub Category",
            "vmodel": _vm.videoForm.sub_category_id,
            "tagPlaceholder": "Add Sub Category",
            "formGroupClass": "form-group mb-3",
            "optionsLimit": _vm.optionsLimit,
            "isTaggable": _vm.isTaggable,
            "activeData": _vm.subCategories,
            "closeOnSelect": true,
            "isLoading": _vm.isSubCategoryLoading,
            "options": _vm.subCategories.map(function (data) {
              return data.id;
            }),
            "custom-label": function customLabel(subCategoryId) {
              return _vm.subCategories.length && _vm.subCategories.find(function (data) {
                return data.id == subCategoryId;
              }).name;
            }
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.videoForm, "sub_category_id", $event);
            },
            "update:activeData": function updateActiveData($event) {
              _vm.subCategories = $event;
            },
            "update:active-data": function updateActiveData($event) {
              _vm.subCategories = $event;
            },
            "tag": _vm.addSubCategory
          }
        }) : _vm._e(), _c('input-textarea', {
          attrs: {
            "labelName": "Description",
            "labelFor": "description",
            "vmodel": _vm.videoForm.description,
            "formGroupClass": "form-group mb-3",
            "inputClass": "custom-input",
            "rules": {
              required: true
            }
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.videoForm, "description", $event);
            }
          }
        }), _c('custom-file-upload', {
          attrs: {
            "label": "Upload Video",
            "labelName": "video",
            "vmodel": _vm.videoForm.video,
            "formGroupClass": "form-group mb-3",
            "inputName": "video",
            "rules": {
              required: _vm.rowId ? false : true
            },
            "fieldName": "video",
            "apiErrors": _vm.apiErrors,
            "fileUrl": _vm.videoUrl,
            "placeholder": "hello"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.videoForm, "video", $event);
            }
          }
        }), _c('input-image', {
          attrs: {
            "labelName": "Upload Thumbnail ",
            "labelFor": "thumbnail",
            "vmodel": _vm.form.thumbnail,
            "formGroupClass": "form-group mb-3",
            "inputName": "thumbnail",
            "rules": {
              required: false
            },
            "imgSrc": _vm.form.thumbnail_url,
            "apiErrors": _vm.apiErrors,
            "fieldName": "thumbnail",
            "deleteIcon": false
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "thumbnail", $event);
            }
          }
        }), _c('div', {
          staticClass: "form-group mb-3 text-center"
        }, [_c('button', {
          staticClass: "addBtn",
          attrs: {
            "type": "submit"
          }
        }, [_vm._v(" " + _vm._s(_vm.rowId ? _vm.isSubmit ? "Updating" : "Update" : _vm.isSubmit ? "Adding" : "Add") + " Video ")])])], 1)];
      }
    }])
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }